@media screen and (max-width: 800px) {
  .landingpage__container {
    display: flex;
    flex-direction: column;
    background-color: #0d0d0e;
    justify-content: center;
    align-items: center;
  }
  .contact__container {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 500px;
    background-color: #111111;
    justify-content: center;
    align-self: center;
    align-items: center;
    margin: 50px 0px;
    border: 1px solid rgb(39, 39, 39);
    border-radius: 25px;
  }
}
@media screen and (min-width: 801px) and (max-width: 1500px) {
  .landingpage__container {
    display: flex;
    flex-direction: column;
    background-color: #0d0d0e;
    justify-content: center;
    align-items: center;
  }
  .contact__container {
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 500px;
    background-color: #111111;
    justify-content: center;
    align-self: center;
    align-items: center;
    margin: 50px 0px;
    border: 1px solid rgb(39, 39, 39);
    border-radius: 25px;
  }
}
@media screen and (min-width: 1501px) {
  .landingpage__container {
    display: flex;
    flex-direction: column;
    background-color: #0d0d0e;
    justify-content: center;
    align-items: center;
  }
  .contact__container {
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 500px;
    background-color: #111111;
    justify-content: center;
    align-self: center;
    align-items: center;
    margin: 50px 0px;
    border: 1px solid rgb(39, 39, 39);
    border-radius: 25px;
  }
}
