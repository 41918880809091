@media screen and (max-width: 1000px) {
  .contact__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
  }
  .contact__wrapper {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 500px;
    background-color: #111111;
    justify-content: center;
    align-self: center;
    align-items: center;
    margin: 50px 0px;
    border: 1px solid rgb(66, 66, 66);
    border-radius: 25px;
  }
  .contact__container h2 {
    color: #f8f7f4;
    user-select: none;
    text-shadow: -2px -2px 10px rgb(136, 136, 136),
      2px -2px 10px rgb(78, 78, 78);
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
  input {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    font-family: BlinkMacSystemFont;
    background-color: #202020;
    color: #f8f7f4;
    border: 1px solid rgb(39, 39, 39);
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    border-radius: 7px;
  }
  textarea {
    width: 100%;
    height: 100px;
    resize: none;
    box-sizing: border-box;
    font-family: BlinkMacSystemFont;
    background-color: #202020;
    border: 1px solid rgb(39, 39, 39);
    color: #f8f7f4;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    border-radius: 7px;
  }
  .contact__button {
    background-color: #202020;
    border-radius: 7px;
    width: 30%;
    height: 3vh;
    color: #f8f7f4;
    font-family: BlinkMacSystemFont;
    font-weight: 500;
    border: 1px solid rgb(39, 39, 39);
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0px;
    cursor: pointer;
  }
  .error__message {
    color: rgb(182, 0, 0);
  }
  .success__message {
    color: rgb(0, 92, 0);
  }
}

@media screen and (min-width: 1001px) {
  .contact__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40vw;
  }
  .contact__container h2 {
    color: #f8f7f4;
    user-select: none;
    text-shadow: -2px -2px 10px rgb(136, 136, 136),
      2px -2px 10px rgb(78, 78, 78);
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
  input {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    font-family: BlinkMacSystemFont;
    background-color: #202020;
    color: #f8f7f4;
    border: 1px solid rgb(39, 39, 39);
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    border-radius: 7px;
  }
  textarea {
    width: 100%;
    height: 100px;
    resize: none;
    box-sizing: border-box;
    font-family: BlinkMacSystemFont;
    background-color: #202020;
    border: 1px solid rgb(39, 39, 39);
    color: #f8f7f4;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    border-radius: 7px;
  }
  .contact__button {
    background-color: #202020;
    border-radius: 7px;
    width: 40%;
    height: 3vh;
    color: #f8f7f4;
    font-family: BlinkMacSystemFont;
    font-weight: 500;
    border: 1px solid rgb(39, 39, 39);
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0px;
    cursor: pointer;
  }
  .error__message {
    color: rgb(182, 0, 0);
  }
  .success__message {
    color: rgb(0, 92, 0);
  }
}
