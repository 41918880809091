@media screen and (max-width: 600px) {
  .textwithtitle__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    color: white;
  }
  .textwithtitle__wrapper {
    width: 91vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .textwithtitle__wrapper h1 {
    text-shadow: -2px -2px 10px rgb(136, 136, 136),
      2px -2px 10px rgb(78, 78, 78);
  }
  .textwithtitle__wrapper iframe {
    width: 90vw;
    height: 30vh;
  }
  .textwithtitle__wrapper p {
    text-align: justify;
    font-size: 1rem;
  }
}
@media screen and (min-width: 601px) {
  .textwithtitle__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    color: white;
  }
  .textwithtitle__wrapper {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .textwithtitle__wrapper h1 {
    text-shadow: -2px -2px 10px rgb(136, 136, 136),
      2px -2px 10px rgb(78, 78, 78);
  }
  .textwithtitle__wrapper iframe {
    width: 40vw;
    height: 40vh;
  }
  .textwithtitle__wrapper p {
    text-align: justify;
  }
}
