.footer__container {
  background-color: #111111;
}
.footer__container h3 {
  text-align: center;
  user-select: none;
  color: rgb(212, 212, 212);
  font-size: 15px;
  font-weight: 500;
  margin: 10px;
  font-family: "Courier New", Courier, monospace;
  text-shadow: -10px 10px 25px rgb(196, 196, 196),
    -10px -10px 25px rgb(136, 136, 136), 10px -10px 25px rgb(78, 78, 78);
}
