.textwithimage__container {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out 0.5s, transform 0.6s ease-out 0.5s;
}

.textwithimage__container.visible {
  opacity: 1;
  transform: translateY(0);
}
.textwithimage__wrapper img {
  transition: transform 0.4s ease-in-out;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .textwithimage__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vh;
    box-sizing: border-box;
  }
  .textwithimage__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8% 3%;
  }
  .textwithimage__wrapper img {
    width: 100%;
    max-height: 25vh;
    object-fit: cover;
    box-shadow: 1px 1px 2px black, 0 0 20px rgb(56, 56, 56),
      0 0 3px rgb(112, 112, 112);
  }
  .textwithimage__wrapper h1 {
    color: rgb(233, 233, 233);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 1rem;
    font-weight: 400;
    text-align: justify;
    user-select: none;
  }
}
@media screen and (min-width: 501px) and (max-width: 1100px) {
  .textwithimage__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-height: 65vh;
  }
  .textwithimage__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4% 7%;
  }
  .textwithimage__wrapper img {
    width: 100%;
    max-height: 35vh;
    object-fit: cover;
    box-shadow: 1px 1px 2px black, 0 0 20px rgb(56, 56, 56),
      0 0 3px rgb(112, 112, 112);
  }
  .textwithimage__wrapper h1 {
    color: rgb(233, 233, 233);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 1rem;
    font-weight: 400;
    text-align: justify;
    user-select: none;
    width: 85%;
  }
}
@media screen and (min-width: 1101px) {
  .textwithimage__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    min-height: 65vh;
  }
  .textwithimage__container.reverse {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 80vh;
  }
  .textwithimage__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 2% 5%;
  }
  .textwithimage__wrapper img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    box-shadow: 1px 1px 2px black, 0 0 25px rgb(56, 56, 56),
      0 0 5px rgb(112, 112, 112);
  }
  .textwithimage__wrapper h1 {
    color: rgb(233, 233, 233);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
    user-select: none;
    width: 80%;
  }
}
