@media (max-width: 600px) {
  .interactiveheaders__container {
    text-align: center;
    color: white;
    padding-top: 5vh;
    height: 80vh;
  }

  .interactiveheaders__headers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    user-select: none;
  }

  .interactiveheaders__container h2 {
    cursor: pointer;
    transition: color 0.3s ease;
    width: 22%;
    flex: 1;
    min-width: 150px;
    font-size: 1.1rem;
    font-weight: 500;
    text-shadow: -2px -2px 10px rgb(136, 136, 136),
      2px -2px 10px rgb(78, 78, 78);
  }

  .interactiveheaders__container h2:hover {
    color: #007bff;
  }

  .interactiveheaders__fade-in {
    animation: fadeIn 0.5s ease-in-out;
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
@media (min-width: 601px) {
  .interactiveheaders__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    min-height: 100vh;
  }

  .interactiveheaders__headers {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    user-select: none;
  }

  .interactiveheaders__container h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;
    width: 22%;
    flex: 1;
    min-width: 150px;
    text-shadow: -2px -2px 10px rgb(136, 136, 136),
      2px -2px 10px rgb(78, 78, 78);
    margin: 0;
    height: 100%;
  }
  .interactiveheaders__container h2:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;
    width: 22%;
    flex: 1;
    min-width: 150px;
    text-shadow: -2px -2px 10px #007bff, 2px -2px 10px rgb(78, 78, 78);
    margin: 0;
    height: 100%;
  }
  .interactiveheaders__container h2:first-of-type {
    border-right: solid white 1px;
  }
  .interactiveheaders__container h2:nth-child(3) {
    border-left: solid white 1px;
  }
  .interactiveheaders__container h2:nth-child(4) {
    border-left: solid white 1px;
  }

  .interactiveheaders__container h2:hover {
    color: #007bff;
  }

  .interactiveheaders__fade-in {
    animation: fadeIn 0.5s ease-in-out;
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
