/* Keyframes for fade-in and slide-up animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.welcome__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.welcome__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  animation: fadeIn 2s ease-in-out; /* Fade-in animation for the wrapper */
}

video {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}

.welcome__wrapper h1 {
  color: rgb(233, 233, 233);
  font-family: Epilogue, sans-serif;
  font-size: 3rem;
  font-weight: 700;
  text-shadow: -2px -2px 10px rgb(136, 136, 136), 2px -2px 10px rgb(78, 78, 78);
  text-align: center;
  margin: 0px 30px;
  user-select: none;
  animation: slideUp 1s ease-out 0.5s both; /* Slide-up animation with delay */
}

.welcome__wrapper h2 {
  color: rgb(233, 233, 233);
  font-family: Epilogue, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  margin: 50px 0px;
  width: 60%;
  user-select: none;
  animation: slideUp 1s ease-out 1s both; /* Slide-up animation with longer delay */
}

.welcome__container h3 {
  align-self: flex-end;
  color: rgb(51, 51, 51);
  margin: 0;
  font-size: 9px;
  user-select: none;
}

@media screen and (max-width: 800px) {
  .welcome__wrapper h1 {
    font-size: 2rem;
  }
  .welcome__wrapper h2 {
    font-size: 1rem;
    width: 80%;
  }
}

@media screen and (min-width: 801px) and (max-width: 1300px) {
  .welcome__wrapper h1 {
    font-size: 2.4rem;
  }
  .welcome__wrapper h2 {
    font-size: 1.2rem;
    width: 70%;
  }
}
