@media screen and (max-width: 1000px) {
  .slider__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95vw;
    user-select: none;
  }
  .slider__container a {
    font-size: 1.5rem;
    color: white;
  }
  .slider__container a:visited {
    color: white;
    text-decoration: none;
  }
}

@media screen and (min-width: 1001px) {
  .slider__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    user-select: none;
    height: 70vh;
  }
  .legend {
    width: 50px;
  }
  .slider__container a {
    font-size: 1rem;
    color: white;
  }
  .slider__container a:visited {
    color: white;
    text-decoration: none;
  }
}
